<template>
  <div
    :class="[
      'menu-section mb-4',
      { 'px-3': !isSubsection },
      { subsection: isSubsection },
      { isDirty },
      entity.section_type ? `type--${entity.section_type}` : '',
    ]"
    @mouseover.stop="isHovering = true"
    @mouseleave.stop="isHovering = false"
  >
    <slot>
      <div
        v-if="entity.section_type !== 'hide_title'"
        class="menu-section--top mb-3"
      >
        <BaseEditable
          v-if="entity.title"
          ref="title"
          tag="span"
          :content="entity.title"
          :mods="myMods.title"
          class="menu-section--title blanch--font"
          @update="updateSectionAction('title', $event)"
          @dirtyUpdate="adjustDirtScore"
        />
        <div v-if="entity.section_subtitle_note" class="menu-section--subtitle">
          <template v-if="entity.section_subtitle_note[0] === '{'">
            <component
              :is="getTagForElem(elem.type)"
              v-for="elem of JSON.parse(entity.section_subtitle_note).content"
              :key="JSON.stringify(elem.text)"
              >{{ elem.text }}</component
            >
          </template>
          <BaseEditable
            v-else
            tag="span"
            :content="entity.section_subtitle_note"
            :mods="myMods.section_subtitle_note"
            @update="
              updateSectionAction('section_subtitle_note', $event, entity.id)
            "
            @dirtyUpdate="adjustDirtScore"
          />
        </div>
      </div>
      <div class="menu-section--items">
        <BaseButton
          v-if="!itemIds.length && isShowingEditUI"
          md-icon="add"
          text-color="green"
          color="green"
          small
          round
          outline
          @click="stageNewItem({ sectionId: entity.id })"
          >Add an Item</BaseButton
        >
        <BaseMenuComponent
          v-else
          name="MenuSectionItems"
          :item-ids="itemIds"
          :section="entity"
          item-class="asap--font"
          @addItem="addItemAction({ sectionId: entity.id, index: $event })"
          @deleteSectionItem="
            removeItemAction({ sectionId: entity.id, itemId: $event.id })
          "
          @itemUpdated="updateItemAction(entity.id, $event)"
          @dragEvent="dragEventAction"
        />
      </div>
      <div v-if="subsections.length" class="menu-section--sub-menus">
        <WithMenuSection
          v-for="subsection of subsections"
          :id="subsection.id"
          v-slot="{
            isSectionLoading: isSubsectionLoading,
            section: theSubsection,
            itemMods: subsectionItemMods,
            myMods: subsectionMods,
          }"
          :key="subsection.id"
        >
          <BaseMenuComponent
            :is="subsection.is_callout ? 'MenuSectionCallout' : 'MenuSection'"
            :item-mods="subsectionItemMods"
            :my-mods="subsectionMods"
            :is-subsection="true"
            :entity="theSubsection"
            @updateTitle="
              updateSectionAction('title', $event, theSubsection.id)
            "
            @updateSubtitle="
              updateSection('section_subtitle_note', $event, theSubsection.id)
            "
            @dirtyUpdate="adjustDirtScore"
          />
        </WithMenuSection>
      </div>
      <!-- <EntityModButtons
      v-if="isHovering && isShowingEditUI"
      class="section-buttons--wrap"
      bottom
      add-btn-color="blue"
      entity-name="Section"
      show-delete
      :hide-buttons="isSelected && !isHovering"
      @add="$emit('addSectionAfter')"
      @delete="$emit('deleteSection')"
    /> -->
    </slot>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import MenuSectionMixin from '@mixins/menu-section'

import MenuSectionCallout from '@menu/MenuSectionCallout'
import EntityModButtons from '@menu/EntityModButtons'

export default {
  name: 'DailyBeetMenuSection',
  components: { MenuSectionCallout, Draggable, EntityModButtons },
  mixins: [MenuSectionMixin],
}
</script>

<style lang="scss">
@import '@design';

.menu-section {
  position: relative;
  &.subsection {
    margin-top: 50px;
  }
  &.isDirty {
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 5px;
      content: '';
      background: aquamarine;
      transform: translateY(-100%);
    }
  }
}

.menu-section--title {
  margin-bottom: 0.1em;
  font-size: 60px;
  line-height: 0.7;
  // @media (max-width:600px) {
  //   position:sticky;
  //   top:0;
  //   background-color:white;
  // }
}

.menu-section--subtitle {
  font-size: 1.15rem;
  font-weight: 500;
  span {
    font-family: 'motor', serif;
  }
  i {
    font-family: 'Asap', sans-serif;
    // font-weight:300;
    font-size: 0.8rem;
  }
}

.menu-entity--buttons-wrap.Section-buttons {
  z-index: 929;

  $distance: 30px;

  &.top {
    top: 0;
    padding-bottom: $distance;
    transform: translateY(-100%);
  }
  &.bottom {
    top: auto;
    bottom: 0;
    padding-top: $distance;
    transform: translateY(100%);
  }
}

.list-enter-active,
.list-leave-active {
  transition: all 2s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0.1;
  transform: translateY(100%);
}
</style>
